import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import LocationMap from './LocationMap';
import EmailLink from './EmailLink';
import PhoneLink from './PhoneLink';

class ContactInfo extends React.Component {

  state = {
    showMap: false,
    mapLocation: null,
  }

  showMap = (mapSrc) => {
    this.setState({ showMap: true, mapSrc: mapSrc });
  }

  hideMap = () => {
    this.setState({ showMap: false });
  }

  render() {
    return (
      <aside id="contact" className="info aside section">
        <div className="section-inner">
          <h2 className="heading sr-only">Contact Information</h2>
          <div className="content">
            <ul className="list-unstyled">
              <li>
                <div className="d-flex align-items-center">
                  <div>
                    <FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth />
                    <span className="sr-only">Location:</span>
                  </div>
                  <div>
                    {this.props.locations.map((loc, i) => <button className="d-block href-link" key={i} onClick={() => this.showMap(loc.map)}>{loc.name}</button>)}
                  </div>
                </div>
              </li>
              <li><FontAwesomeIcon icon={faEnvelope} fixedWidth /><span className="sr-only">Email:</span><EmailLink email={this.props.contact.email} /></li>
              <li><FontAwesomeIcon icon={faPhone} fixedWidth /><span className="sr-only">Telephone:</span><PhoneLink phone={this.props.contact.mobile} /></li>
              <li><FontAwesomeIcon icon={faLinkedin} fixedWidth /><span className="sr-only">LinkedIn:</span><a href={this.props.contact.linkedIn}>LinkedIn</a></li>
            </ul>
            <button className="btn btn-primary btn-contact d-md-none"
              onClick={this.props.onShowContact}><FontAwesomeIcon icon={faPaperPlane} /> Send message</button>
          </div>
        </div>
        <LocationMap show={this.state.showMap} src={this.state.mapSrc} onClose={this.hideMap} />
      </aside>
    );
  }
}

export default ContactInfo;