import React from 'react';
import EducationItem from './EducationItem';

class Education extends React.Component {
  render() {
    return (
      <section id="education" className="education section">
        <div className="section-inner">
          <h2 className="heading">Education</h2>
          <div className="content">
            {this.props.education.map((edu, index) => <EducationItem key={index} education={edu} />)}
          </div>
        </div>
      </section>
    );
  }
}

export default Education;