import React from 'react';
import LanguageItem from './LanguageItem';

export default function Languages(props) {
  return (
    <aside id="languages" className="languages aside section">
      <div className="section-inner">
        <h2 className="heading">Languages</h2>
        <div className="content">
          <ul className="list-unstyled">
            {props.languages.map((lang, index) => <LanguageItem key={index} language={lang} />)}
          </ul>
        </div>
      </div>
    </aside>
  );
}