import React from 'react';

class LangSelector extends React.Component {

  render() {
    const { langs, displayCode } = this.props;
    if (langs.length <= 1) return null;

    return (
      langs.map((lang, i) =>
        <span key={i}>
          <button className="href-link language-link" onClick={() => this.props.onLangChange(lang.code)}>{displayCode ? lang.code : lang.name}</button>
          {(i !== langs.length - 1) && <span> | </span>}
        </span>
      )
    );
  }
}

export default LangSelector;