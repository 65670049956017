import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import axios from 'axios';
import PhoneLink from './PhoneLink';
import EmailLink from './EmailLink';

export default function ContactForm(props) {

  const { register, handleSubmit, errors } = useForm();
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [failed, setFailed] = useState(null);

  const inputNameClassNames = classNames('form-control', { 'input-error': errors.name })
  const inputEmailClassNames = classNames('form-control', { 'input-error': errors.email})
  const inputMessageClassNames = classNames('form-control', { 'input-error': errors.message })
  
  useEffect(() => {
    setSent(false);
    setSending(false);
    setFailed(false);
  }, [props.show]);

  const onSubmit = (data) => {
    setSending(true);
    axios.post('/php/sendmail.php', data).then((res) => {
      if (res && res.data === 'Success') {
        setSent(true);
      } else {
        setFailed("Unknown error: " + res.data);
      }
    }).catch((resp) => {
      setFailed(resp.message ? resp.message : resp.toString());
    });
  }

  return (
    <Modal show={props.show} dialogClassName="contact-modal modal-dialog-centered" onHide={props.onClose}>
      <Modal.Header>
        <Modal.Title>
          Contact
          <button className="close" onClick={props.onClose}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        {sent &&
          <div>
            <p className="small mt-3 mb-4 text-center">Thank you.<br />I will be in touch as soon as possible.</p>
            <div className="text-center"><Button variant="light" onClick={props.onClose}>Close</Button></div>
          </div>
        }
        {!sent &&
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <p className="small">Send a message using the form below and I will be in touch as soon as possible.
            Alternatively, call <PhoneLink phone={props.contact.mobile} /> or email <EmailLink email={props.contact.email} /></p>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input name="name" id="name" type="name" maxLength="100" className={inputNameClassNames} ref={register({ required: true, maxLength: 100 })} />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input name="email" id="email" type="email" maxLength="100" className={inputEmailClassNames} ref={register({ required: true, maxLength: 100 })} />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" maxLength="2000" rows="5" className={inputMessageClassNames} ref={register({ required: true, maxLength: 100 })} />
          </div>
          <div className="d-flex justify-content-between">
            <div className="w-50">
              {failed && <div className="error">Error: {failed}</div>}
            </div>
            <div className="text-right w-50">
              {/* <Button variant="light" className="mr-1" onClick={props.onClose}>Cancel</Button> */}
              <Button variant="primary" type="submit" disabled={sending}>Send</Button>
            </div>
          </div>
        </form>}
      </Modal.Body>
    </Modal>
  );
}
