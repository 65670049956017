import React from 'react';
import Header from './Header';
import About from './About';
import Experience from './Experience';
import ContactInfo from './ContactInfo';
import Skills from './Skills';
import Testimonials from './Testimonials';
import Education from './Education';
import Languages from './Languages';
import Footer from './Footer';
import cvEn from './data/cv.json';
import cvEs from './data/cv.es.json';
import MobileMenu from './MobileMenu';
import ContactForm from './ContactForm';

class App extends React.Component {

  state = {
    langs: [
      { name: 'English', code: 'en' }
      // { name: 'Español', code: 'es' }
    ],
    showContact: false,
    cv: cvEn
  }

  handleLangChange = (lang) => {
    switch (lang) {
      case 'es':
        this.setState({ cv: cvEs });
        break;
      default:
        this.setState({ cv: cvEn });
    }
  }
  
  handleShowContact = () => {
    this.setState({ showContact: true });
  }

  render() {
    return (
      <div className="App">
        <div className="d-block d-md-none"><MobileMenu langs={this.state.langs} onLangChange={this.handleLangChange} /></div>
        <Header langs={this.state.langs} name={this.state.cv.name} title={this.state.cv.title}
          onShowContact={this.handleShowContact} onLangChange={this.handleLangChange} />
        <div className="container sections-wrapper">
          <div className="row">
            <div className="primary col-lg-8 col-12">
              <About summary={this.state.cv.summary} />
              <div className="d-block d-lg-none">
                <Skills skills={this.state.cv.skills} />
              </div>
              <Experience experience={this.state.cv.experience} />
              <Education education={this.state.cv.education} />
            </div>
            <div className="secondary col-lg-4 col-12">
              <div id="aside1">
                <ContactInfo onShowContact={this.handleShowContact}
                  locations={this.state.cv.locations} contact={this.state.cv.contact} />
              </div>
              <div className="d-none d-lg-block" >
                <Skills skills={this.state.cv.skills} />
              </div>
              <div id="aside2">
                <Testimonials testimonials={this.state.cv.testimonials} />
              </div>
              <div id="aside3">
                <Languages languages={this.state.cv.languages} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ContactForm contact={this.state.cv.contact} show={this.state.showContact}
          onClose={() => this.setState({ showContact: false })} />
      </div>
    );
  }
}

export default App;
