import React from 'react';

class EmailLink extends React.Component {

  state = {
    email: '(email)'
  }

  componentDidMount() {
    // Attempt to prevent data scraping
    setTimeout(() => {
      this.setState((state, props) => ({
        email: props.email,
      }));
    }, 50);
  }

  render() {
    return (<a className="text-nowrap" href={'mailto:' + this.state.email}>{this.state.email}</a>);
  }
}

export default EmailLink;