import React from 'react';
import TestimonialItem from './TestimonialItem';

class Testimonials extends React.Component {

  render() {
    return (
      <aside id="testimonials" className="testimonials aside section">
        <div className="section-inner">
          <h2 className="heading">Testimonials</h2>
          <div className="content">
            {this.props.testimonials.map((t, index) => <TestimonialItem key={index} testimonial={t} />)}
          </div>
        </div>
      </aside>
    );
  }
}

export default Testimonials;