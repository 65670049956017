import React from 'react';

class Skills extends React.Component {
  render() {
    
    let skills = this.props.skills.map((s, i) => 
      <div className="item" key={i}>
        <div className="skill-heading">
          <h4 className="skill-title">{s.title}</h4>
          {s.years && <h4 className="skill-years">{s.years} years</h4>}
        </div>
        <div className="tech">
          {s.tech.map((t, i) => <span key={i} className="badge badge-pill badge-light">{t}</span> )}
        </div>
      </div>
    );

    return (
      <aside id="skills" className="skills aside section">
        <div className="section-inner">
          <h2 className="heading">Skills</h2>
          <div className="content">
            <div className="skillset">{skills}</div>
          </div>
        </div>
      </aside>
    );
  }
}

export default Skills;