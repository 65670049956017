import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faDownload } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LangSelector from './LangSelector';


class Header extends React.Component {

  render() {

    const { name, title, langs, onLangChange } = this.props;

    return (
      <header className="header">
        <div className="container clearfix">
          <img className="profile-image img-fluid float-left" src="images/rg-profile.jpg" alt={name} />
          <div className="profile-content float-left">
            <h1 className="name">{name}</h1>
            <h2 className="desc">{title}</h2>
          </div>
          <div className="button-container">
            <div className="icons language-links">
              <LangSelector langs={langs} onLangChange={onLangChange} displayCode={false} />
            </div>
            <button className="btn btn-primary btn-contact" onClick={this.props.onShowContact}><FontAwesomeIcon icon={faPaperPlane} /> Contact</button>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Download CV</Tooltip>}>
              <a className="btn btn-primary btn-download" target="_blank" rel="noopener noreferrer" href="/cv/2020-Richard-Gebbie-CV.pdf"><FontAwesomeIcon icon={faDownload} /></a>
            </OverlayTrigger>

          </div>
        </div>
      </header>
    );
  }
}

export default Header;