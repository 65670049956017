import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { HashLink as Link } from 'react-router-hash-link';
import { BrowserRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import LangSelector from './LangSelector';

class MobileMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }
  
  handleMenuClick = () => {
    this.setState({ isOpen: false });
  }

  handleStateChange = (open) => {
    this.setState({ isOpen: open });
  }

  handleLangChange = (lang) => {
    this.props.onLangChange(lang);
    this.handleStateChange(false);
  }

  render() {

    const { langs } = this.props;
  
    return (
      <BrowserRouter>
        <Menu right disableAutoFocus isOpen={this.state.isOpen} onStateChange={(s) => this.handleStateChange(s.isOpen)}>
          
          {/* Language selector */}
          <div className="menu-item">
            <div className="language-links">
              <LangSelector langs={langs} onLangChange={this.handleLangChange} displayCode={true} />
            </div>
          </div>

          {/* Anchor links */}
          <Link smooth to="#about" className="menu-item" onClick={this.handleMenuClick}>Profile</Link>
          <Link smooth to="#skills" className="menu-item" onClick={this.handleMenuClick}>Skills</Link>
          <Link smooth to="#employment" className="menu-item" onClick={this.handleMenuClick}>Employment</Link>
          <Link smooth to="#education" className="menu-item" onClick={this.handleMenuClick}>Education</Link>
          <Link smooth to="#testimonials" className="menu-item" onClick={this.handleMenuClick}>Testimonials</Link>
          <Link smooth to="#contact" className="menu-item" onClick={this.handleMenuClick}>Contact</Link>
          
          {/* Download link */}
          <div className="menu-item">
            <div className="separator-dl">
              <Link smooth to="/cv/2020-Richard-Gebbie-CV.pdf" target="_blank" rel="noopener noreferrer" className="menu-item download" onClick={this.handleMenuClick}>
                <FontAwesomeIcon icon={faDownload} /> PDF
              </Link>
            </div>
          </div>

        </Menu>
      </BrowserRouter>
    );
  }
}

export default MobileMenu;