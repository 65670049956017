import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

export default function LanguageItem(props) {
  let lang = props.language;
  let stars = [];

  for (let i = 0; i < lang.level; i++) {
    stars.push(<FontAwesomeIcon key={i} icon={faStar} />);
  }

  return (
    <li className="item">
      <div>
        <span className="title"><strong>{lang.name}: </strong></span>
        <span className="level">{lang.description}</span>
      </div>
      <div>
        {stars}
      </div>
    </li>
  );
}