import React from 'react';
import ExperienceItem from './ExperienceItem';

class Experience extends React.Component {

  render() {

    return (
      <section id="employment" className="experience section">
        <div className="section-inner">
          <h2 className="heading">Career Highlights</h2>
          <div className="content">
            {this.props.experience.map((exp, index) => <ExperienceItem key={index} experience={exp} />)}
          </div>
        </div>
      </section>
    );
  }
}

export default Experience;