import React from 'react';

class PhoneLink extends React.Component {

  state = {
    phone: {
      tel: '0',
      display: '(mobile)'
    }
  }

  componentDidMount() {
    // Attempt to prevent data scraping
    setTimeout(() => {
      this.setState((state, props) => ({
        phone: props.phone,
      }));
    }, 50);
  }

  render() {
    return (<a className="text-nowrap" href={'tel:' + this.state.phone.tel}>{this.state.phone.display}</a>);
  }
}

export default PhoneLink;