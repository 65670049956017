import React from 'react';
import Moment from 'react-moment';

class EducationItem extends React.Component {

  render() {
    let edu = this.props.education;
    return (
      <div className="item">
        <div className="row">
          <div className="col-8">
            <h3 className="title">
              <span>{edu.title} <span className="grade">{edu.grade}</span></span>
              <div className="place">{edu.location}</div>
            </h3>
          </div>
          <div className="col-4 text-right">
            <div className="year">
              <Moment format="YYYY">{edu.dateFrom}</Moment>
              {new Date(edu.dateFrom).getFullYear() !== new Date(edu.dateTo).getFullYear() && <Moment format="-YYYY">{edu.dateTo}</Moment>}
            </div>
          </div>
        </div>
        {edu.details && <ul className="details">
          {edu.details.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
        }
      </div>
    );
  }

}

export default EducationItem;