import React from 'react';

export default function About(props) {
  return (
    <section id="about" className="about section">
      <div className="section-inner">
        <h2 className="heading">Profile</h2>
        <div className="content">
          {props.summary.map((p, i) => <p key={i}>{p}</p>)}
        </div>
      </div>
    </section>
  );
}