import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

class LocationMap extends React.Component {

  render() {
    return (
      <Modal show={this.props.show} dialogClassName="map-modal" onHide={this.props.onClose}>
        <Modal.Body>
          <div className="map-container">
            <Spinner animation="border" role="status" variant="primary" size="lg">
              <span className="sr-only">Loading...</span>
            </Spinner>
            <iframe title="Map" src={this.props.src}
              width="100%" height="450" frameBorder="0" allowFullScreen=""></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="mx-1">
            <Button variant="light" size="sm" onClick={this.props.onClose}>Close Map</Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default LocationMap;