import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';

class TestimonialItem extends React.Component {
  render() {
    let t = this.props.testimonial;
    return (
      <div className="item">
        <blockquote className="quote">
          <p><FontAwesomeIcon icon={faQuoteLeft} />{t.message}</p>
        </blockquote>
        <p className="source"><span className="name">{t.name}</span><br /><span className="title">{t.position},&nbsp;
          <a href={t.url} rel="noopener noreferrer" target="_blank">{t.company}</a></span></p>
      </div>
    );
  }
}

export default TestimonialItem;