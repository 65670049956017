import React from 'react';
import Moment from 'react-moment';

class ExperienceItem extends React.Component {
  render() {
    let e = this.props.experience;
    return (
      <div className="item">
        <div className="row">
          <div className="col-sm-6">
            <h3 className="title">
              <span>{e.title}</span>
              <div className="place"><a target="_blank" rel="noopener noreferrer" href={e.url}>{e.company}, {e.location}</a></div>
            </h3>
          </div>
          <div className="sub-title col-sm-6">
            <div className="year"><Moment format="MMMM YYYY">{e.dateFrom}</Moment> - <Moment format="MMMM YYYY">{e.dateTo}</Moment></div>
            <div className="contract-type">{e.contractType}</div>
          </div>
        </div>
        <ul className="details">
          {e.details.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
        <div className="tech-stack">
          {e.techStack.map((t, index) => <span key={index} className="badge badge-pill badge-light">{t}</span>)}
        </div>
      </div>
    );
  }
}
export default ExperienceItem;